<style scoped>
.content {
  border-bottom: 1px solid var(--borderColor);
  position: relative;
  padding: 5px 0 5px;
}

.content_btn {
  padding: 8px 15px;
  border-radius: 3px;
  color: white;
  font-weight: bold;
  font-size: 12px;
}
.content_btn_del {
  background: #e05454;
}
.content_btn_edit {
  background: #7390ef;
}
.content_btn_view {
  background: green;
}
.content_btn_preview {
  background: #006180;
}
</style>

<template>
  <div>
    <common-top title="审核" :isShowSearch="false"></common-top>

    <div style="padding: 0 10px; margin-top: 10px">
      <div class="content" v-for="(data, index) in listCon" :key="index">
        <div @click="toUrl(`/messageDetail?id=${data._cid}`)">
          <rn-gaojian :contentData="data"></rn-gaojian>
        </div>
        <div style="display: flex; justify-content: flex-end">
          <!-- <div
            class="content_btn content_btn_edit"
            @click="openDialog(data)"
          >
            修改时间
          </div> -->
          <div
            v-if="data.review == '0'"
            class="content_btn content_btn_edit"
            style="margin-left: 10px"
            @click="showDialog(data, 'agree')"
          >
            审核通过
          </div>

          <div
            v-if="data.review == '1' || data.review == '-1'"
            class="content_btn content_btn_edit"
            style="margin-left: 10px"
            @click="showDialog(data, 'cancelReview')"
          >
            取消审核
          </div>

          <div
            v-if="data.review == '0'"
            class="content_btn content_btn_del"
            style="margin-left: 10px"
            @click="showDialog(data, 'disagree')"
          >
            不通过
          </div>
          <!-- <div
            class="content_btn content_btn_view"
            style="margin-left: 10px"
            @click="toTop(data)"
          >
            <span v-if="!data.colnumTop"> 置顶 </span>
            <span v-else> 取消置顶 </span>
          </div> -->
          <!-- <div
            class="content_btn content_btn_preview"
            style="margin-left: 10px"
          >
            <a
              style="color: white; outline: none; text-decoration: none"
              :href="data.contentLink"
              >预览</a
            >
          </div> -->
        </div>
      </div>
    </div>

    <load-more
      @refresh="onRefresh"
      :load-status="isLoadMore"
      :isShow="loadMoreShow"
    ></load-more>
    <dia-logliu :show="dailogshow" :options="dailogoptions"></dia-logliu>

    <div v-if="isConfirm && curCon">
      <confirm
        :title="`确认操作 `"
        @sure="oprationFun"
        @cancel="isConfirm = false"
      ></confirm>
    </div>

    <no-pass-com v-show="!isUserPm"> </no-pass-com>
    <Modal v-model="isTime" fullscreen :closable="false">
      <div class="chooseDateFormItem">
        <div class="chooseDateFormItemTitle" style="margin-bottom: 5px">
          请选择发布日期：
        </div>
        <div class="detail">
          <DatePicker
            format="yyyy-MM-dd HH:mm"
            type="datetime"
            :options="options3"
            :value="pickerValue"
            @on-change="setStartDateValue"
            placeholder="请选择时间"
            width="200px"
          ></DatePicker>
        </div>
      </div>
      <div slot="footer" style="text-align: center">
        <Button type="info" size="large" @click="chooseDate">确定</Button>
        <Button type="default" size="large" @click="cancelChoose">取消</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
/* eslint-disable  */
import debug from "debug";
import Vue from "vue";
import moment from "moment";
import { wxConPost, getUserPm } from "../../api/httpApi";
import _ from "lodash";

import loadMore from "../../components/loadMoreData.vue";
import rnGaojianFooter from "../../components/rnGaojianFooter.vue";
import rnGaojian from "../../components/rnGaojian.vue";

import diaLogliu from "../../components/dialogLiu.vue";
import confirm from "../../components/commonConfirm.vue";

import commonTop from "../../components/commonTop.vue";
import noPassCom from "../../components/noPassCom.vue";
let curDate = new Date();
const _d = debug("@pages:contentReview");

moment.locale("zh-cn");

export default {
  name: "contentReview",
  data() {
    return {
      options3: {
        disabledDate(date) {
          return date && date.valueOf() > Date.now();
        },
      },
      isUserPm: true,
      listCon: [],
      activeIndex: -1,
      pickerValue: "",
      isLoadMore: true,
      loadMoreShow: false,
      isConfirm: false,
      isTime: false,
      curCon: null,
      curIndex: -1,
      opration: "",

      dailogshow: false, // 提示框显示
      duration: 1000,
      dailogoptions: {
        content: "",
        autoClose: true,
        textColor: "#ccc",
      }, // 提示框选项
    };
  },

  methods: {
    back() {
      this.$router.replace("/tougaoEditor");
    },
    async onRefresh() {
      const limit = this.$store.state.limit ? this.$store.state.limit : 10;
      console.log(this.listCon.length < limit, this.listCon.length, limit);

      const query = {
        column: [
          "视点",
          "时评",
          "资讯",
          "T视频",
          "健康体检",
          "政策解读",
          "工作证挂失",
          "青春广铁",
          "工会",
          "组织",
          "宣传",
          "广铁电视",
          "集团信息",
          "宣讲",
          "广铁健康",
          "我型我秀",
        ],
        review: {
          skip: this.listCon.length,
          limit: 20,
          sort: {
            updated: -1,
          },
        },
        unreview: {
          skip: this.listCon.length,
          limit: 20,
          sort: {
            updated: -1,
          },
        },
      };
      try {
        const ret = await wxConPost(
          "/services/gttt-content-mgt/content/getReviewList",
          query
        );
        const dataList = [];
        const data = ret.unreview.value;
        if (data.length == 0) {
          this.loadMoreShow = true;
          this.isLoadMore = false;
        } else {
          let listData = [];
          for (const item of data) {
            let columntype = "text";
            if (item.video) {
              columntype = "video";
            } else if (item.img.length > 0) {
              columntype = "image";
              listData = item;
              Object.assign(listData, {
                type: columntype,
                keywordsStr: item.keywords ? item.keywords.join(" ") : "",
              });
              dataList.unshift(listData);
            }
          }

          let arr1 = _.sortBy(dataList, "time");
          arr1 = _.reverse(_.sortBy(arr1, "colnumTop"));

          this.listCon = this.listCon.concat(arr1);

          this.loadMoreShow = true;
          this.isLoadMore = true;
        }
      } catch (error) {
        console.log("加载列表错误");
        console.log(error);
      }
    },
    async chooseDate() {
      console.log(this.pickerValue);
      try {
        const ret = await wxConPost(
          "/services/gttt-content-mgt/content/updateTime",
          {
            _cid: this.timeCid,
            time: this.pickerValue,
          }
        );
        this.diaLogFun("操作成功");
        this.getList();
        this.isTime = false;
      } catch (error) {
        this.diaLogFun("操作失败");
        this.isTime = false;
      }
    },
    setStartDateValue(date) {
      console.log(date);
      this.pickerValue = date;
    },
    async cancelChoose() {
      this.isTime = false;
    },
    // 获取栏目信息
    async getList() {
      const query = {
        column: [
          "视点",
          "时评",
          "资讯",
          "T视频",
          "健康体检",
          "政策解读",
          "工作证挂失",
          "青春广铁",
          "工会",
          "组织",
          "宣传",
          "广铁电视",
          "集团信息",
          "宣讲",
          "广铁健康",
          "我型我秀",
        ],
        review: {
          skip: 0,
          limit: 20,
          sort: {
            updated: -1,
          },
        },
        unreview: {
          skip: 0,
          limit: 20,
          sort: {
            updated: -1,
          },
        },
      };

      try {
        const ret = await wxConPost(
          "/services/gttt-content-mgt/content/getReviewList",
          query
        );
        const data = ret.unreview.value;
        const dataList = [];
        if (data && data.length == 0) {
          this.loadMoreShow = true;
          this.isLoadMore = false;
        } else {
          for (const item of data) {
            let columntype = "text";
            if (item.video) {
              columntype = "video";
            } else if (item.img.length > 0) {
              columntype = "image";
              let listData = item;
              Object.assign(listData, {
                type: columntype,
                keywordsStr: item.keywords ? item.keywords.join(" ") : "",
              });

              dataList.unshift(listData);
            }
          }

          this.listCon = _.reverse(_.sortBy(dataList, "updated"));
          console.log("数据");
          console.log(this.listCon);

          // 显示加载
          this.loadMoreShow = true;
          // 显示加载
          this.isLoadMore = true;
        }
      } catch (error) {}
    },
    diaLogFun(msg) {
      this.dailogshow = true;
      this.dailogoptions.content = msg;
      this.dailogoptions.textColor = "white";
      setTimeout(() => {
        this.dailogshow = false;
      }, 2000);
    },

    toEdit(cid) {
      this.$router.push({
        path: "/tougaoEditor",
        query: {
          cid,
        },
      });
    },
    toUrl(url) {
      this.$router.push({
        path: url,
      });
    },
    openDialog(data) {
      this.isTime = true;
      console.log(data.updated);
      this.pickerValue = moment(data.updated).format("YYYY-MM-DD HH:mm");
      console.log(this.pickerValue);
      this.timeCid = data._cid;
    },
    showDialog(curCon, opration) {
      this.curCon = curCon;
      this.opration = opration;
      this.isConfirm = true;
    },
    oprationFun() {
      console.log("-------", this.opration);
      if (this.opration == "agree") {
        this.agree();
        return;
      }
      if (this.opration == "disagree") {
        this.disagree();
      }
      if (this.opration == "cancelReview") {
        this.cancelReview();
      }
    },

    // 审核通过
    async agree() {
      console.log("-obj------", this.curCon);
      try {
        const obj = this.curCon;
        console.log("-obj------111111", obj);
        // 设置提交审核状态
        await wxConPost("/services/gttt-content-mgt/content/review", {
          _cid: obj._cid,
          doc: { review: "1" },
        });
        this.diaLogFun("已将该内容审核为:通过");
        // 修改本条数据状态
        obj["review"] = "1";
        Vue.set(this.listCon, this.curIndex, obj);
        this.curCon = null;
        this.curIndex = -1;
      } catch (e) {
        this.diaLogFun("操作异常");
      }
    },
    // 取消审核
    async cancelReview() {
      try {
        const obj = this.curCon;
        console.log("-obj------111111", obj);
        // 设置提交审核状态
        await wxConPost("/services/gttt-content-mgt/content/review", {
          _cid: obj._cid,
          doc: { review: "0" },
        });
        this.diaLogFun("已取消审核");
        // 修改本条数据状态
        obj["review"] = "0";
        Vue.set(this.listCon, this.curIndex, obj);
        this.curCon = null;
        this.curIndex = -1;
      } catch (e) {
        this.diaLogFun("操作异常");
      }
    },

    // 提交审核
    async disagree() {
      const obj = this.curCon;
      if (obj.review === "-1") {
        this.$Notice.info({ title: "提示", desc: "不需要变更" });
        return;
      }
      try {
        // 设置提交审核状态
        await wxConPost("/services/gttt-content-mgt/content/review", {
          _cid: obj._cid,
          doc: { review: "-1" },
        });
        this.diaLogFun("已将该内容审核为:未通过");
        // 修改本条数据状态
        obj["review"] = "-1";
        Vue.set(this.listCon, this.curIndex, obj);
        this.curCon = null;
        this.curIndex = -1;
      } catch (e) {
        this.diaLogFun("操作异常");
      }
    },

    // 置顶
    async toTop(obj) {
      if (!_.isBoolean(obj.colnumTop)) {
        obj.colnumTop = false;
      }
      try {
        await wxConPost("/services/gttt-content-mgt/tougao/colnumreview", {
          _cid: obj._cid,
          doc: { colnumTop: !obj.colnumTop },
        });
        this.diaLogFun("操作成功");
        this.getList();
      } catch (error) {}
    },
    // 预览
    preview() {},
  },

  async created() {
    this.getList();
  },
  beforeCreate() {
    document.title = "路局内容审核发布";
  },

  components: {
    loadMore,
    rnGaojianFooter,
    rnGaojian,
    noPassCom,
    commonTop,
    diaLogliu,
    confirm,
  },
  filters: {
    dateFormat(time) {
      return moment(time).startOf("minutes").fromNow();
    },
  },
};
</script>
